import Image from 'next/image'
import { cn } from '@/lib/utils'
import { ROUTES } from '@/v1/constants'
import CallToActionButton from '@/v1/@core/CallToActionButton'
import { H3, RenderParsedContent } from '@/v1/@core/typography/Typography'
import { ITabContent, TabClassNames } from '@/custom-components/tab-section/type'

interface TabContentProps {
  data: ITabContent
  tabClassNames?: TabClassNames
}

const TabContent = ({ data, tabClassNames }: TabContentProps) => {
  return (
    <>
      <div className={cn('', tabClassNames?.parent_content_wrapper)}>
        {!!data?.title?.html && (
          <div className={'mb-6 pt-6 lg:pt-0 xl:mb-12'}>
            <H3 className={cn('px-5 lg:px-0', data?.title?.classes)}>{data?.title?.html}</H3>
            {!!data?.content && (
              <>
                <RenderParsedContent className={'px-5 pt-2 lg:px-0'}>{data?.content}</RenderParsedContent>
              </>
            )}
          </div>
        )}

        <div
          className={cn('flex flex-col items-center lg:flex-row lg:gap-10 xl:gap-20', tabClassNames?.content_wrapper)}
        >
          <div className={cn('order-1 mx-auto py-6 lg:py-0', data?.image?.wrClassName, tabClassNames?.image_wrapper)}>
            <Image
              src={data?.image?.src}
              alt={data?.image?.alt || (data?.altTxt ?? '')}
              className={cn('mx-auto', tabClassNames?.image)}
            />
          </div>

          <div className={cn('order-2', tabClassNames?.content)}>
            {!!data?.detail_content && (
              <RenderParsedContent className={'mb-2'}>{data?.detail_content}</RenderParsedContent>
            )}

            {!!data?.detail?.length && (
              <div className={'space-y-6 px-2 pb-6 lg:px-0 lg:pb-0'}>
                {data.detail.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <div className='flex items-center gap-1'>
                          {!!item?.icon && <div className='h-8 w-8'> {item?.icon} </div>}
                          {!!item?.title?.html && (
                            <H3 className={cn('mb-2', item?.title?.classes)}>{item?.title?.html}</H3>
                          )}
                        </div>
                        {!!item?.content && <RenderParsedContent>{item?.content}</RenderParsedContent>}
                        {!!item?.button && (
                          <CallToActionButton
                            text={item?.button?.text}
                            icon={item?.button?.icon ?? true}
                            href={item?.button?.href ?? ROUTES.PRICING}
                            className={cn('mt-6', item?.button?.className)}
                          />
                        )}
                      </div>
                    </>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TabContent
